import React, { useRef, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import HTMLFlipBook from 'react-pageflip';

import beach1 from '../../static/images/beach1.jpeg';
import beach2 from '../../static/images/beach2.jpeg';
import cabin1 from '../../static/images/cabin1.jpeg';
import cabin2 from '../../static/images/cabin2.jpeg';
import cabin3 from '../../static/images/cabin3.jpeg';
import frozen1 from '../../static/images/frozen1.jpeg';
import halloween1 from '../../static/images/halloween1.jpeg';
import love1 from '../../static/images/love1.jpeg';
import ny1 from '../../static/images/ny1.jpeg';
import ny2 from '../../static/images/ny2.jpeg';
import nye1 from '../../static/images/nye1.jpeg';
import spain1 from '../../static/images/spain1.jpeg';
import spain2 from '../../static/images/spain2.jpeg';
import spain3 from '../../static/images/spain3.jpeg';
import spain4 from '../../static/images/spain4.jpeg';
import spain5 from '../../static/images/spain5.jpeg';
import spain6 from '../../static/images/spain6.jpeg';
import spain7 from '../../static/images/spain7.jpeg';
import spain8 from '../../static/images/spain8.jpeg';
import spain9 from '../../static/images/spain9.jpeg';
import spain10 from '../../static/images/spain10.jpeg';

import BirthdayPage from './page-templates/BirthdayPage';
import Page from './page-templates/Page';
import { PageCover } from './page-templates/PageCover';

import './BirthdayBook.css';

const pages = [
  {
    image: love1,
    text: 'You gave me a smoochie at your last birthday',
  },
  {
    image: spain2,
    text: 'Hehe you look pretty',
  },
  {
    image: halloween1,
    text: 'Its a Maaaario!',
  },
  {
    image: spain1,
    text: 'Cochinillo',
  },
  {
    image: spain3,
    text: 'Under an orange tree',
  },
  {
    image: spain4,
    text: 'Segovia',
  },
  {
    image: spain5,
    text: 'Immy took this picture',
  },
  {
    image: spain6,
    text: 'And this one!',
  },
  {
    image: spain7,
    text: 'Inside the Alhambra',
  },
  {
    image: spain8,
    text: 'Outside of the Alhambra!',
  },
  {
    image: spain9,
    text: 'That chick cheated on my best bro',
  },
  {
    image: spain10,
    text: 'We got a good pic',
  },
  {
    image: ny1,
    text: 'Ice skating attempt',
  },
  {
    image: ny2,
    text: 'With sweet treat',
  },
  {
    image: nye1,
    text: 'Happy New Years!',
  },
  {
    image: frozen1,
    text: 'Frozen',
  },
  {
    image: cabin1,
    text: 'Cabin time',
  },
  {
    image: cabin2,
    text: 'Cowgirl!',
  },
  {
    image: cabin3,
    text: 'Smoochie',
  },
  {
    image: beach1,
    text: 'Buffalo pizza time',
  },
  {
    image: beach2,
    text: 'Love you baby',
  },
];

const BirthdayBook = () => {
  const [isExploding, setIsExploding] = useState(false);
  const photoRefs = useRef([]);

  const onFlip = ({ data: number }) => {
    if (number === 1) {
      setIsExploding(true);
    }
  };

  const setPhotoRef = (el, index) => {
    photoRefs.current[index] = el;
  };

  return (
    <div style={{ margin: '2rem' }}>
      {isExploding && <ConfettiExplosion duration="3000" particleCount="250" />}
      <HTMLFlipBook
        className="flip-book"
        height={900}
        maxHeight="90%"
        maxShadowOpacity={0.5}
        maxWidth="80%"
        minWidth={250}
        mobileScrollSupport
        showCover
        size="stretch"
        width={600}
        onFlip={onFlip}>
        <PageCover>Happy 25th Birthday Katie!</PageCover>
        {pages.map(({ image, text }, index) => {
          return (
            <Page
              key={text}
              ref={(el) => setPhotoRef(el, text)}
              number={index + 1}>
              <BirthdayPage img={image} text={text} />
            </Page>
          );
        })}

        <PageCover>The End</PageCover>
      </HTMLFlipBook>
    </div>
  );
};

export default BirthdayBook;
