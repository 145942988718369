import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import leftArrow from '../../static/images/left-arrow.png';

const BackToProjects = () => {
  return (
    <Link style={{ textDecoration: 'none' }} to="/projects">
      <div
        style={{
          borderRadius: '10px',
          padding: '0.5rem',
          display: 'flex',
          border: '2px solid black',
          maxWidth: '150px',
          height: '20px',
        }}>
        <img
          alt="left-arrow"
          src={leftArrow}
          style={{ float: 'left', maxWidth: '100%', paddingRight: '15px' }}
        />
        <Typography
          component="div"
          sx={{ fontSize: '14px', color: 'black' }}
          variant="h6">
          Back To Games
        </Typography>
      </div>
    </Link>
  );
};

export default BackToProjects;
