import React, { useEffect, useState } from 'react';

import O from '../../static/images/O.png';
import X from '../../static/images/X.png';
import BackToProjects from '../../utils/Content/BackToProjects';
import ContentContainer from '../../utils/Content/ContentContainer';

import './TicTacToe.css'; // Import the CSS file

const App = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [status, setStatus] = useState('Next player: X');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const winner = calculateWinner(board);
    if (winner) {
      if (winner === 'X') {
        setStatus(`You beat Katie! You are better than her`);
      } else {
        setStatus(`You fricking loser the computer moves are random`);
      }
    } else if (!isXNext) {
      // Disable clicks while the computer is making its move
      setIsDisabled(true);
      // Add delay for computer's move
      setTimeout(() => {
        const emptySquares = board.reduce((acc, val, idx) => {
          return val === null ? acc.concat(idx) : acc;
        }, []);
        if (emptySquares.length > 0) {
          const randomMove =
            emptySquares[Math.floor(Math.random() * emptySquares.length)];
          const newBoard = [...board];
          newBoard[randomMove] = 'O';
          setBoard(newBoard);
          setIsXNext(true);
        }
        setIsDisabled(false); // Re-enable clicks after the computer's move
      }, 1000); // 1 second delay
    } else {
      setStatus('');
    }
  }, [board, isXNext]);

  const handleClick = (index) => {
    if (board[index] || calculateWinner(board) || isDisabled) {
      return;
    }

    const newBoard = [...board];
    newBoard[index] = 'X';
    setBoard(newBoard);
    setIsXNext(false);
  };

  const calculateWinner = (currBoard) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i += 1) {
      const [a, b, c] = lines[i];
      if (
        currBoard[a] &&
        currBoard[a] === currBoard[b] &&
        currBoard[a] === currBoard[c]
      ) {
        return currBoard[a];
      }
    }

    return null;
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setStatus('');
  };

  const renderSquare = (index) => {
    return (
      <button
        className="square"
        disabled={isDisabled}
        type="button"
        onClick={() => handleClick(index)}>
        {board[index] && (
          <img
            alt={board[index]}
            className="piece"
            src={board[index] === 'X' ? X : O}
          />
        )}
      </button>
    );
  };

  return (
    <ContentContainer>
      <BackToProjects />
      <div className="game">
        <div className="game-board">
          <div className="board-row">
            {renderSquare(0)}
            {renderSquare(1)}
            {renderSquare(2)}
          </div>
          <div className="board-row">
            {renderSquare(3)}
            {renderSquare(4)}
            {renderSquare(5)}
          </div>
          <div className="board-row">
            {renderSquare(6)}
            {renderSquare(7)}
            {renderSquare(8)}
          </div>
        </div>
        <div className="game-info">
          <div>{status}</div>
          {calculateWinner(board) && (
            <button type="button" onClick={resetGame}>
              Reset Game
            </button>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default App;
