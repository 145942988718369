import React from 'react';

import './Wordle.css';

const NUM_ROWS = 6;
const NUM_LETTERS = 5;
const Board = ({ guesses, currentGuess, secretWord }) => {
  const getCellLetter = (row, col) => {
    if (row < guesses.length) {
      if (col < guesses[row].length) {
        return guesses[row][col];
      }
    } else if (row === guesses.length) {
      if (col < currentGuess.length) {
        return currentGuess[col];
      }
    }
    return '';
  };

  const getCellType = (row, col) => {
    if (row < guesses.length) {
      if (col < guesses[row].length) {
        if (guesses[row][col] === secretWord[col]) {
          return 'correct';
        }
        if (secretWord.includes(guesses[row][col])) {
          return 'present';
        }
        return 'absent';
      }
    }
    return 'empty';
  };

  return (
    <div className="wordle-board">
      {Array(NUM_ROWS)
        .fill(null)
        .map((_, row) => {
          return (
            <div className="wordle-word">
              {Array(NUM_LETTERS)
                .fill(null)
                .map((__, col) => {
                  return (
                    <div
                      className={`wordle-cell wordle-cell-${getCellType(
                        row,
                        col,
                      )}`}>
                      {getCellLetter(row, col)}
                    </div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default Board;
