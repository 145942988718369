import React, { forwardRef } from 'react';

import './Page.css';

const Page = forwardRef(({ number, children }, ref) => {
  return (
    <div ref={ref} className="page">
      <div className="page-content">{children}</div>
      {number && (
        <div className="page-footer">
          <div style={{ padding: '1rem' }}>{number}</div>
        </div>
      )}
    </div>
  );
});

export default Page;
