import React from 'react';

const KEYBOARD_ROW_1 = 'QWERTYUIOP'.split('');
const KEYBOARD_ROW_2 = 'ASDFGHJKL'.split('');
const KEYBOARD_ROW_3 = 'ZXCVBNM'.split('');
const KEYBOARD = [KEYBOARD_ROW_1, KEYBOARD_ROW_2, KEYBOARD_ROW_3];

const Keyboard = ({ currentGuess, setCurrentGuess, setGuesses, guesses }) => {
  const onClick = (character) => {
    if (currentGuess.length >= 5) {
      return;
    }

    setCurrentGuess([...currentGuess, character.toUpperCase()]);
  };

  const onEnter = () => {
    if (currentGuess.length === 5) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess([]);
    }
  };

  const onBackspace = () => {
    if (currentGuess.length > 0) {
      setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1));
    }
  };

  return (
    <div className="wordle-keyboard">
      {KEYBOARD.map((row, index) => {
        return (
          <div className="wordle-keyboard-row">
            {index === 2 && <Key character="ENTER" onClick={() => onEnter()} />}
            {row.map((character) => {
              return (
                <Key character={character} onClick={() => onClick(character)} />
              );
            })}
            {index === 2 && <Key character="<" onClick={() => onBackspace()} />}
          </div>
        );
      })}
    </div>
  );
};

const Key = ({ character, onClick }) => {
  return (
    <button
      className={`wordle-keyboard-key ${
        character === 'ENTER' && 'wordle-keyboard-key-enter'
      } ${character === '<' && 'wordle-keyboard-key-backspace'}`}
      type="button"
      onClick={onClick}>
      {character}
    </button>
  );
};

export default Keyboard;
