import React, { forwardRef } from 'react';

import './PageCover.css';

export const PageCover = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="page-cover">
      <div className="page-content">
        <h2 className="title">{props.children}</h2>
      </div>
    </div>
  );
});
