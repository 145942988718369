import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BackToProjects from '../../utils/Content/BackToProjects';
import ContentContainer from '../../utils/Content/ContentContainer';
import ContentImage from '../../utils/Content/ContentImage';

export default function KatieButton() {
  const [imageUrl, setImageUrl] = useState('');

  const fetchImage = async () => {
    // Decide randomly whether to fetch a dog or pizza image
    const fetchDog = Math.random() < 0.5;

    const UNSPLASH_URL = 'https://api.unsplash.com/photos/random';
    const UNSPLASH_API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY;

    const query = fetchDog ? 'dog' : 'pizza bagel';

    try {
      fetch(`${UNSPLASH_URL}?query=${query}&client_id=${UNSPLASH_API_KEY}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.urls && data.urls.regular) {
            setImageUrl(data.urls.regular);
          } else {
            console.error('No image found');
          }
        })
        .catch((error) => {
          console.error('Error fetching image:', error);
        });
    } catch (err) {
      setImageUrl('');
    }
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [location]);

  const buttonStyle = {
    display: 'inline-block',
    padding: '15px 30px',
    margin: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#ff4081',
    border: 'none',
    borderRadius: '50%',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  };

  return (
    <ContentContainer>
      <BackToProjects />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <button
          style={buttonStyle}
          type="button"
          onClick={fetchImage}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.2)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}>
          Katie Button
        </button>
      </div>
      <ContentImage src={imageUrl} />
    </ContentContainer>
  );
}
