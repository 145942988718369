import React, { useState } from 'react';

import ContentContainer from '../../../utils/Content/ContentContainer';

import Board from './Board';
import Keyboard from './Keyboard';

const SECRET_WORD = ['K', 'A', 'T', 'I', 'E'];
const Wordle = () => {
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState([]);

  return (
    <ContentContainer>
      <div className="wordle-elements">
        <div className="wordle-title">Wordle or What</div>
        <Board
          currentGuess={currentGuess}
          guesses={guesses}
          secretWord={SECRET_WORD}
        />
        <Keyboard
          currentGuess={currentGuess}
          guesses={guesses}
          setCurrentGuess={setCurrentGuess}
          setGuesses={setGuesses}
        />
      </div>
    </ContentContainer>
  );
};

export default Wordle;
