import React from 'react';

import './BirthdayPage.css';

const BirthdayPage = ({ img, text }) => {
  return (
    <>
      <div className="image-container">
        <img alt="2004" className="image" src={img} />
      </div>

      <div className="description">{text}</div>
    </>
  );
};

export default BirthdayPage;
