import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import {
  HOME_ROUTE,
  KATIE_THORSBERG,
  PHOTOBOOK,
  PROJECTS,
  PROJECTS_ROUTE,
} from '../constants/NavBar';
import KATIE_THORSBERG_IMAGE from '../static/images/katie-thorsberg.jpeg';
import NavButton from '../utils/Nav/NavButton';
import NavImage from '../utils/Nav/NavImage';
import NavText from '../utils/Nav/NavText';

const NavBar = () => (
  <Box>
    <AppBar position="static">
      <Toolbar>
        <NavText text={KATIE_THORSBERG} />
        <NavButton text={PHOTOBOOK} to={HOME_ROUTE} />
        <NavButton text={PROJECTS} to={PROJECTS_ROUTE} />
        <NavImage alt={KATIE_THORSBERG} src={KATIE_THORSBERG_IMAGE} />
      </Toolbar>
    </AppBar>
  </Box>
);

export default NavBar;
